import { GetStripeAccountData, Organization } from '@signupsoftware/server'
import {
  Button,
  CopyText,
  Modal,
  NoticeText,
  useEffectOnce,
  useIsMobile,
  useRouter,
} from '@unimpaired/react'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAdminContext } from '../hooks/useAdminContext'
import { useQuery } from 'react-query'
import { apiClient } from '../../config/axios.config'
import { AxiosError } from 'axios'
import { getApi } from '../helpers/getApi'

type Props = {
  isOpen: boolean
  refetch: () => void
  issues?: string[]
  eventId?: number
  onClose: () => void
}
export const EventPublishModal = (props: Props) => {
  const { organization } = useAdminContext()
  const { location } = useRouter()
  const [createAccountUrl, setCreateAccountUrl] = useState<string>()

  useEffectOnce(() => {
    if (!organization) return
    if (!createAccountUrl) return
    window.location.href = createAccountUrl
  }, [createAccountUrl])

  const { data: accountData, ...query } = useQuery({
    queryKey: ['get-stripe-account', organization.id],
    queryFn: async () =>
      apiClient
        .get<GetStripeAccountData>(
          `get-stripe-account/${organization.stripeAccountId}`,
        )
        .then((res) => res.data)
        .catch((err) => {
          if (err instanceof AxiosError) {
            toast.error(err.response?.data?.message)
          }
          return undefined
        }),
    enabled: !!organization && !!organization.stripeAccountId,
  })

  const createStripeAccount = async () => {
    const accountLink = await apiClient.post('/create-stripe-account', {
      organizationId: organization.id,
      organizationName: organization.name,
      organizationEmail: organization.email,
      businessType: 'individual',
      // returnUrl: 'https://stlswing.dance',
      returnUrl:
        import.meta.env.VITE_WEBSITE_URL + location.pathname + '/success',
      refreshUrl:
        import.meta.env.VITE_WEBSITE_URL + location.pathname + '/refresh',
      // refreshUrl: 'https://stlswing.dance',
      // import.meta.env.VITE_WEBSITE_URL + location.pathname + '/refresh',
    })

    setCreateAccountUrl(accountLink.data.url)
  }

  const isMobile = useIsMobile()
  const publishable = props.issues?.length === 0

  return (
    <Modal
      isOpen={props.isOpen}
      title='Publish'
      type={'right-panel'}
      className='sm:w-1/2'
      onClose={props.onClose}>
      {/* <NoticeText
        type='error'
        className='p-4'
        fullWidth
        text='Attach banking information'>
        <Button variant='warn' className='w-full' onClick={createStripeAccount}>
          connect your bank
        </Button>
      </NoticeText> */}

      {!publishable && (
        <div className='space-y-4 p-4'>
          <p className='text-sm text-gray-600'>
            It looks like there are a few more things you need to finish before
            we can publish your class.
          </p>

          {props.issues?.map((issue, i) => (
            <NoticeText
              type='error'
              className='items-stretch'
              key={issue + i}
              text={issue}></NoticeText>
          ))}
        </div>
      )}

      {/*
      props?.issues &&
        props.issues.length === 0 &&
        organization.stripeAccountId &&  */}
      {!props.issues && (
        <div className='p-4 space-y-4'>
          <p className='text-sm text-gray-600'>
            Publishing your class will dissalow you from changing the price
          </p>

          <p className='text-sm text-gray-600'>
            The first tickets revenue will be withheld by us as a software fee
          </p>

          {/* {!accountData?.canAcceptTransfers && (
            <div>
              <NoticeText
                type='warn'
                fullWidth
                className='p-4'
                text="You can start accepting payment right away, but you'll need to set up and link a Stripe account before funds can be paid out to you.">
                test
              </NoticeText>
            </div>
          )} */}
        </div>
      )}

      {props?.issues && props.issues.length === 0 && (
        <div className='p-4 w-full'>
          <div>
            <p className='font-bold text-lg sm:text-2xl text-gray-800 text-center pb-4'>
              How it works
            </p>

            <p className='font-bold text-gray-600 text-md sm:text-xl py-2 px-4 sm:py-4 text-left'>
              After you publish
            </p>

            <div className='w-full border-b' />
            <ol className='p-4 space-y-2'>
              <li>
                <p>
                  You can <strong>scan tickets</strong> from your phone by going
                  to{' '}
                  <a className='text-blue-400 font-medium cursor-pointer'>
                    this link
                  </a>
                </p>
              </li>
              <li>
                <p>
                  People can <strong>pay for your class</strong> by going to{' '}
                  <a className='text-blue-400 font-medium cursor-pointer'>
                    this link
                  </a>
                </p>
              </li>
              <li>
                <p>
                  People can <strong>see published classes</strong> at{' '}
                  <a className='text-blue-400 font-medium cursor-pointer'>
                    this link
                  </a>
                </p>
              </li>
            </ol>

            <p className='font-bold text-gray-600 text-md sm:text-xl py-2 px-4 sm:py-4 text-left'>
              Receiving money from ticket sales
            </p>

            <div className='w-full border-b' />
            <ol className='p-4 space-y-2'>
              <li>
                <p>
                  You must attach your bank with{' '}
                  <a className='text-blue-400 font-medium cursor-pointer'>
                    this link
                  </a>{' '}
                  to receive money.{' '}
                </p>
              </li>
              <li>
                <p>
                  You will receive a specific tax form (depending on your
                  organization type) each year
                  {/* <a className='text-blue-400 font-medium cursor-pointer'>
                    this link
                  </a> */}
                </p>
              </li>
            </ol>

            <p className='font-bold text-gray-600 text-md sm:text-xl py-2 px-4 sm:py-4 text-left'>
              Software Pricing
            </p>
            <div className='w-full border-b' />
            <ol className='p-4 space-y-2'>
              <li>
                For each event, the <strong>first ticket sold</strong> will be
                withheld as a software fee. You are charged nothing unless a
                ticket sale is made.
              </li>
              <li>
                There is a monthly pricing cap for event ticket sales. Each
                week, the software fee is recalculated based on the total ticket
                sales for the month.
              </li>
            </ol>

            <p className='p-4 font-medium text-red-400'>
              Until you attach your bank account, the received funds be held in
              an escrow account. It will be transferred after you attach your
              account.
            </p>

            <div className='border-b' />
          </div>
          <Button
            variant='raised'
            className='w-full h-20'
            onClick={async () => {
              if (props.eventId) {
                await getApi('groupClass').updateOne(props.eventId, {
                  published: true,
                })
                toast.success(
                  'Class published.  This is now visible on your storefront page',
                )
                props.refetch()
              }
              props.onClose()
            }}>
            Publish Class
          </Button>
        </div>
      )}

      {/* <p className='text-sm p-4 text-gray-600'>
        Publishing your class is completely free! However, the revenue from the
        first ticket </div>sold will be withheld as payment.
      </p> */}
      {/* {accountData?.canAcceptTransfers &&
        publishable &&
        accountData?.hasAttachedBankAccount && (

        )} */}
    </Modal>
  )
}

type LiProps = {
  size?: 'big'
  title: string
  description: string
  number: number
}
const Li = ({ title, number, description }: LiProps) => (
  <div>
    <p className='font-bold text-gray-600 text-md sm:text-xl py-2 px-4 sm:py-4 text-left'>
      <strong className='px-2'>{number}.</strong>
      {title}
    </p>
    <div className='w-full border-b' />
    <p className='text-xs text-gray-500 px-4 pt-1 pb-2'>{description}</p>
  </div>
)
