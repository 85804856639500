import { GroupClass, SocialDance } from '@signupsoftware/server'
import {
  EventProduct,
  expandEventProductDates,
  getRRuleDates,
} from './dates.helpers'
import { addMonths, compareAsc, isAfter, isBefore } from 'date-fns'
import { ArrayInfer } from '@unimpaired/utils'
import { toast } from 'react-toastify'
import { capitalCase } from 'change-case'

export const getDates = (groupClass: EventProduct) => {
  if (groupClass.type === 'Progressive')
    return getRRuleDates(groupClass?.recurrence || '')
  return [groupClass.startTime]
}
// Custom comparison function
export const sortEventProducts = (a: EventProduct, b: EventProduct) => {
  // If priorities are different, prioritize higher priority
  if (a.priority && b.priority && a.priority !== b.priority) {
    return b.priority - a.priority
  }

  return compareAsc(a.startTime, b.startTime)
}

export const filterStorefront = <T extends EventProduct[]>(
  products: T,
  date: Date,
) =>
  products
    .flatMap((groupClass) => {
      if (groupClass.type === 'Single') return [groupClass]
      if (groupClass.type === 'Progressive') return [groupClass]
      return expandEventProductDates(groupClass)
    })
    .filter(
      (groupClass) =>
        isAfter(groupClass.startTime, date) &&
        isBefore(groupClass.startTime, addMonths(date, 1)),
    )
    .sort(sortEventProducts) as T

type FullEventData = GroupClass | SocialDance
type AllEventKeys = (keyof GroupClass & keyof SocialDance)[]
const eventRequirementIusses = <T extends Record<string, any>>(
  eventKeys: (keyof T)[],
  event: Partial<T>,
) => {
  return eventKeys.reduce((acc, curr) => {
    const isMissing = (key: keyof T) => {
      if (curr !== key) return false
      if (eventKeys.includes(key) && !event[key]) return true
      return false
    }
    if (isMissing('startTime')) return [...acc, 'please add a start time']
    if (isMissing('endTime')) return [...acc, 'please add an end time']
    if (isMissing('type')) return [...acc, 'please add a recurrence type']
    if (isMissing('danceType')) return [...acc, 'please add a dance type']
    if (isMissing('difficultyLevelId'))
      return [...acc, 'please add a difficultyLevel']
    if (isMissing('headline')) return [...acc, 'please add a headline']
    if (isMissing('name')) return [...acc, 'please add a name']
    if (isMissing('venueId')) return [...acc, 'please add a venue']
    if (isMissing('price')) return [...acc, 'please add a price']
    if (isMissing('recurrence')) return [...acc, 'please add recurrence rules']
    return acc
  }, [] as string[])
}

const allEventRequirements = (event: Partial<FullEventData>) =>
  eventRequirementIusses(
    ['name', 'startTime', 'endTime', 'type', 'venueId'],
    event,
  )

export const canCreateDanceIssues = (event: Partial<SocialDance>) => [
  ...allEventRequirements(event),
  ...eventRequirementIusses([], event),
]
export const canCreateClassIssues = (event: Partial<GroupClass>) => {
  if (!event) return ['please add class information']
  const singleClassIssues = [
    ...allEventRequirements(event),
    ...eventRequirementIusses(['danceType', 'difficultyLevelId'], event),
  ]
  if (event.type === 'Single') return singleClassIssues
  return [
    ...singleClassIssues,
    ...eventRequirementIusses(['recurrence'], event),
  ]
}
export const canPublishClassIssues = (event: Partial<GroupClass>) => {
  const canCreateIssues = canCreateClassIssues(event)
  const canPublishIssues = eventRequirementIusses(
    ['description', 'headline', 'pricingOptions'],
    event,
  )
  return [...canCreateIssues, ...canPublishIssues]
}

export const toastUpdatedFields = <T extends Record<string, any>>(data: T) => {
  toast.success(
    `Successfully Updated ${Object.keys(data)
      .map((string) => capitalCase(string))
      .join(' and ')}`,
  )
}
